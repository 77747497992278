var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { useTranslation } from "react-i18next";
import Select, { components } from "react-select";
import { DropDownIndicatore } from "../DropDownIndicatore";
import { FilterTitle } from "../SideFilter/MultiSelection/MultiSelection-styles";
var MONTHS_DATA = [
    { id: 1, name: "Janvier" },
    { id: 2, name: "Février" },
    { id: 3, name: "Mars" },
    { id: 4, name: "Avril" },
    { id: 5, name: "Mai" },
    { id: 6, name: "Juin" },
    { id: 7, name: "Juillet" },
    { id: 8, name: "Août" },
    { id: 9, name: "Septembre" },
    { id: 10, name: "Octobre" },
    { id: 11, name: "Novembre" },
    { id: 12, name: "Décembre" },
];
export var MonthInput = function (_a) {
    var _b;
    var props = __rest(_a, []);
    var t = useTranslation(['news']).t;
    var langCode = ((_b = document.getElementById("language-code")) === null || _b === void 0 ? void 0 : _b.value) || "en";
    var MONTHS_DATA_TRANSLATIONS = MONTHS_DATA.map(function (month) {
        return { id: month.id, month: new Date(Date.UTC(2000, month.id)).toLocaleString(langCode, { month: 'long' }) };
    });
    var options = MONTHS_DATA_TRANSLATIONS.map(function (item) { return ({
        value: item.id,
        label: item.month
    }); });
    var MultiValue = function (_a) {
        var index = _a.index, getValue = _a.getValue, props = __rest(_a, ["index", "getValue"]);
        var maxToShow = 1;
        var values = getValue();
        return index < maxToShow ? (_jsx(components.MultiValue, __assign({}, props, { children: props.children }))) : index === maxToShow ? (_jsxs(components.MultiValue, __assign({}, props, { children: [" + ", values.length - 1, " ", t('selected'), " "] }))) : null;
    };
    var customStyles = {
        multiValueLabel: function (provided) { return (__assign(__assign({}, provided), { backgroundColor: "#dae6f0", borderRadius: "0px", color: "#095797", fontWeight: "600", paddingTop: "3px", paddingBottom: "3px" })); },
        multiValueRemove: function (provided) { return (__assign(__assign({}, provided), { backgroundColor: "#dae6f0", color: "#095797", borderRadius: "0", cursor: "pointer", paddingTop: "3px", paddingLeft: "5px", "&:hover": {
                backgroundColor: "#dae6f0",
                color: "red"
            } })); },
        valueContainer: function (provided) { return (__assign(__assign({}, provided), { flexWrap: "nowrap" })); },
        placeholder: function (provided) { return (__assign(__assign({}, provided), { fontSize: "1rem", color: "#647287" })); }
    };
    var MultiValueRemove = function (props) {
        return (_jsx(components.MultiValueRemove, __assign({}, props, { children: _jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/x-icon.svg"), alt: "close" }) })));
    };
    var Option = function (props) {
        return (_jsx("div", { children: _jsx(components.Option, __assign({}, props, { children: _jsxs("div", __assign({ className: "d-flex align-items-center" }, { children: [_jsx("input", { type: "checkbox", className: "me-2", checked: props.isSelected, onChange: function () { return null; } }), _jsx("label", { children: props.label })] })) })) }));
    };
    var DropdownIndicator = function (props) {
        return (_jsx(components.DropdownIndicator, __assign({}, props, { children: _jsx(DropDownIndicatore, {}) })));
    };
    return (_jsxs(React.Fragment, { children: [_jsxs(FilterTitle, { children: [" ", t(props.title), "  "] }), _jsx("label", __assign({ htmlFor: "react-select-months-input", style: { opacity: "0", position: "absolute", left: "0", pointerEvent: "none" } }, { children: "months" })), _jsx(Select, { closeMenuOnSelect: false, instanceId: 'months', classNamePrefix: "pn-select", isMulti: true, isClearable: false, options: options, hideSelectedOptions: false, onChange: props.onChange, value: props.months, styles: customStyles, placeholder: t("mois"), components: { DropdownIndicator: DropdownIndicator, MultiValue: MultiValue, Option: Option, MultiValueRemove: MultiValueRemove, IndicatorSeparator: function () { return null; } } })] }));
};
