import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { InputYearContainer } from "./YearInput-styles";
import { useTranslation } from "react-i18next";
import DebouncedInput from './DebouncedInput';
import { FilterTitle } from "../SideFilter/MultiSelection/MultiSelection-styles";
export var YearInput = function (_a) {
    var yearInput = _a.yearInput, onChange = _a.onChange;
    var t = useTranslation(['news']).t;
    return (_jsxs(React.Fragment, { children: [_jsx(FilterTitle, { children: t("Année") }), _jsx(InputYearContainer, { children: _jsx(DebouncedInput, { onChange: onChange, value: yearInput, id: "year" }) })] }));
};
