import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import DebounceInput from "react-debounce-input";
var unwantedChar = ["e", "E", "+", "-", "."];
var handleRemoveUnwantedChar = function (e) {
    unwantedChar.includes(e.key) && e.preventDefault();
};
var DebouncedInput = function (_a) {
    var onChange = _a.onChange, value = _a.value, id = _a.id;
    var handleIncrease = function () {
        if (value > 0) {
            onChange({ target: { value: +value + 1 } });
        }
    };
    var handleDecrease = function () {
        if (value > 0) {
            onChange({ target: { value: +value - 1 } });
        }
    };
    return (_jsxs(React.Fragment, { children: [_jsx("div", { children: _jsx(DebounceInput, { type: "number", minLength: 1, debounceTimeout: 1000, value: value, onChange: onChange, id: id, min: "0", onKeyDown: handleRemoveUnwantedChar, style: { color: "#647287" } }) }), _jsx("img", { className: "year-input-increase", src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/chevron-up-blue.svg"), alt: "arrow up", onClick: handleIncrease }), _jsx("img", { className: "year-input-decrease", src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/chevron-down-blue.svg"), alt: "arrow down", onClick: handleDecrease })] }));
};
export default DebouncedInput;
