var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Text, Tag, DateContainer, ImageContainer, Title, Subtitle } from "../../Styles/SharedStyledComponents";
import { Container } from "../Views/views-styles";
import { Row } from "../../shared-components/Ui/Row";
import { sliceStringAfterMaxLength } from "../../../../utils/Utils";
export var BonsCoupsCard = function (_a) {
    var _b;
    var item = _a.item, noimage = _a.noimage;
    var langCode = ((_b = document.getElementById("language-code")) === null || _b === void 0 ? void 0 : _b.value) || "en";
    var urlPrefix = langCode == "fr" ? "/fr/bonscoups" : "/en/bonscoups";
    var options = { year: "numeric", month: "long", day: "numeric" };
    var f = Intl.DateTimeFormat(langCode, options);
    var getCategory = function () {
        if (langCode === 'fr') {
            return item.category_fr || item.category_en;
        }
        else {
            return item.category_en || item.category_fr;
        }
    };
    return (_jsx(React.Fragment, { children: _jsx(Container, __assign({ style: noimage ? { borderBottom: 0 } : {} }, { children: _jsxs(Row, { children: [!noimage && (_jsx("div", __assign({ className: "col-md-5 ps-md-0 mb-3" }, { children: _jsx("a", __assign({ href: "".concat(urlPrefix, "/").concat(item.slug) }, { children: _jsx(ImageContainer, __assign({ width: 20, height: 0, marginBottom: 14 }, { children: _jsx("img", { src: item.entreprise.logo ? "".concat(item.entreprise.logo) : "".concat(process.env.IMG_URL, "/static/vendors/images/default-Image-company.png"), alt: "banner" }) })) })) }))), _jsxs("div", __assign({ className: noimage ? "col-md-12 px-1" : "col-md-7 mb-3 mb-md-0" }, { children: [_jsx(Title, { children: _jsx("a", __assign({ href: "".concat(urlPrefix, "/").concat(item.slug) }, { children: (langCode === "fr" && item.titre_fr) || (langCode === "en" && !item.titre_en) ? item.titre_fr : item.titre_en })) }), _jsx(Subtitle, __assign({ style: noimage ? { fontSize: "1rem" } : {} }, { children: langCode === 'fr'
                                    ? sliceStringAfterMaxLength(30, item.entreprise.nom_usuel_fr) ? sliceStringAfterMaxLength(30, item.entreprise.nom_usuel_fr) : sliceStringAfterMaxLength(30, item.entreprise.nom_usuel_en)
                                    : sliceStringAfterMaxLength(30, item.entreprise.nom_usuel_en) ? sliceStringAfterMaxLength(30, item.entreprise.nom_usuel_en) : sliceStringAfterMaxLength(30, item.entreprise.nom_usuel_fr) })), _jsxs(Text, { children: [_jsx(DateContainer, { children: f.format(new Date(item.date_creation)) }), _jsx("p", { children: langCode === "fr" ? sliceStringAfterMaxLength(100, item.description_fr) : sliceStringAfterMaxLength(100, item.description_en) }), _jsxs("div", __assign({ className: "flex-wrap" }, { children: [getCategory() && _jsx(Tag, { children: getCategory() }), item.sector && _jsxs(Tag, { children: [" ", item.sector.title, " "] })] }))] })] }))] }) })) }));
};
