var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React from "react";
import Select, { components } from "react-select";
import { DropDownIndicatore } from "../DropDownIndicatore";
import { FilterTitle } from "../SideFilter/MultiSelection/MultiSelection-styles";
import { LabelColor } from "../SideFilter/MultiSelection/MultiSelection-styles";
import { useTranslation } from "react-i18next";
var MultiSelect = function (props) {
    var _a;
    var hasColors = props.colors;
    var t = useTranslation(["multi-selection"]).t;
    var langCode = ((_a = document.getElementById("language-code")) === null || _a === void 0 ? void 0 : _a.value) || "en";
    React.useState(function () { }, []);
    // Cant' we just send this as props?
    var getOptionLabel = function (option) {
        if (option.nom_fr) {
            return langCode === "fr" ? option.nom_fr : option.nom_en;
        }
        else if (option.nom) {
            return option.nom;
        }
        else if (option.title) {
            return option.title;
        }
        else if (option.titre) {
            return option.titre;
        }
        else if (option.label) {
            return option.label;
        }
        else if (option.value) {
            return option.value;
        }
        else if (option["title_".concat(langCode)]) {
            return option["code"] ? "".concat(option["code"], " - ").concat(option["title_".concat(langCode)]) : option["title_".concat(langCode)];
        }
    };
    var getOptionValue = function (option) {
        return option["code"] || option["id"];
    };
    var options = props.data.map(function (item) { return ({
        value: getOptionValue(item),
        color: item.color,
        slug: item.slug,
        label: getOptionLabel(item),
    }); });
    var MultiValue = function (_a) {
        var index = _a.index, getValue = _a.getValue, props = __rest(_a, ["index", "getValue"]);
        var maxToShow = 1;
        var values = getValue();
        return index < maxToShow ? (_jsx(components.MultiValue, __assign({}, props, { children: props.children }))) : index === maxToShow ? (_jsxs(components.MultiValue, __assign({}, props, { children: [" ", "+ ", values.length - 1, " ", t("selected"), " "] }))) : null;
    };
    var customStyles = {
        multiValueLabel: function (provided) { return (__assign(__assign({}, provided), { backgroundColor: "#dae6f0", color: "#095797", fontWeight: "600", borderRadius: "0px", paddingTop: "3px", paddingBottom: "3px" })); },
        multiValueRemove: function (provided) { return (__assign(__assign({}, provided), { backgroundColor: "#dae6f0", color: "#095797", borderRadius: "0", cursor: "pointer", width: "32px", paddingTop: "3px", paddingLeft: "5px", "&:hover": {
                backgroundColor: "#dae6f0",
                color: "red",
            } })); },
        placeholder: function (provided) { return (__assign(__assign({}, provided), { fontSize: "1rem", color: "#647287" })); },
    };
    var MultiValueRemove = function (props) {
        return (_jsx(components.MultiValueRemove, __assign({}, props, { children: _jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/x-icon.svg"), alt: "close" }) })));
    };
    var Option = function (props) {
        return (_jsx("div", { children: _jsx(components.Option, __assign({}, props, { children: _jsxs("div", __assign({ className: "d-flex align-items-top" }, { children: [hasColors && (_jsx(LabelColor, { className: "label-color", style: { backgroundColor: props.data.color } })), _jsx("input", { type: "checkbox", className: "me-2 mt-1", checked: props.isSelected, onChange: function () { return null; } }), _jsx("label", __assign({ style: { flex: 1 } }, { children: props.label }))] })) })) }));
    };
    var DropdownIndicator = function (props) {
        return (_jsx(components.DropdownIndicator, __assign({}, props, { children: _jsx(DropDownIndicatore, {}) })));
    };
    return (_jsxs("div", __assign({ className: "mb-3" }, { children: [_jsxs(FilterTitle, { children: [" ", t(props.title), " "] }), _jsx(Select, { closeMenuOnSelect: false, classNamePrefix: "pn-select", isMulti: true, isClearable: false, options: options, hideSelectedOptions: false, onChange: props.handleClick, value: props.SelectedChoices, styles: customStyles, placeholder: props.placeHolder, isDisabled: props.disabled, noOptionsMessage: function () { return t("No result found"); }, components: {
                    DropdownIndicator: DropdownIndicator,
                    MultiValue: MultiValue,
                    Option: Option,
                    MultiValueRemove: MultiValueRemove,
                    IndicatorSeparator: function () { return null; },
                } })] })));
};
export default MultiSelect;
