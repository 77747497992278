var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { useContext } from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import BonsCoupsContext from "../../../application/Context/BonsCoupsContext";
import { MonthInput } from "../shared-components/Monthinput/MonthInput";
import { YearInput } from "../shared-components/YearInput/YearInput";
import MultiSelect from "../shared-components/MultiSelect/MultiSelect";
export var Filters = function () {
    var _a = useContext(BonsCoupsContext), selectedCategories = _a.selectedCategories, handleCategoryChange = _a.handleCategoryChange, categories = _a.categories, months = _a.months, handleMonthChange = _a.handleMonthChange, handleYear = _a.handleYear, yearInput = _a.yearInput, filtersIsLoading = _a.filtersIsLoading;
    var t = useTranslation(["news"]).t;
    return (_jsx(React.Fragment, { children: _jsxs(motion.div, __assign({ initial: { opacity: 0 }, animate: { opacity: 1 }, transition: { duration: 0.2 }, exit: { opacity: 0 } }, { children: [_jsx(MultiSelect, { title: t("Types"), placeHolder: t("Sélectionner un ou des types"), data: categories.map(function (e) {
                        return { id: e[0], value: e[1] };
                    }), handleClick: handleCategoryChange, SelectedChoices: selectedCategories }), _jsxs("div", __assign({ className: "row" }, { children: [_jsx("div", __assign({ className: "col-md-8 p-0" }, { children: _jsx(MonthInput, { title: "Mois", type: "checkbox", months: months, onChange: handleMonthChange, disabled: filtersIsLoading }) })), _jsx("div", __assign({ className: "col-md-4 p-0 ps-md-1" }, { children: _jsx(YearInput, { type: "number", yearInput: yearInput, onChange: handleYear, disabled: filtersIsLoading }) }))] }))] })) }));
};
