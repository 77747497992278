var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\nborder-bottom: 1px solid ", ";\nmargin:30px 0;\n"], ["\n\nborder-bottom: 1px solid ", ";\nmargin:30px 0;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.grisClair;
});
export var Title = styled.h4(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\ncolor : ", ";\nfont-size:1.5rem;\nfont-family:'Roboto',sans-serif;\nfont-weight:bold;\nmargin-bottom:16px;\n"], ["\ncolor : ", ";\nfont-size:1.5rem;\nfont-family:'Roboto',sans-serif;\nfont-weight:bold;\nmargin-bottom:16px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.blueFonce;
});
export var Mrc = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\ndisplay:flex;\nalign-items:center;\nfont-family:'Open Sans',sans-serif;\nfont-size:1rem;\ngap:8px;\nmargin-bottom:1rem;\np{\n    margin:0;\n}\n"], ["\ndisplay:flex;\nalign-items:center;\nfont-family:'Open Sans',sans-serif;\nfont-size:1rem;\ngap:8px;\nmargin-bottom:1rem;\np{\n    margin:0;\n}\n"])));
export var CompanyDetails = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\ndisplay:flex;\nmargin:15px 0;\ngap:1.5rem;\n\n@media(max-width: 600px){\n    flex-direction:column;\n}\n"], ["\ndisplay:flex;\nmargin:15px 0;\ngap:1.5rem;\n\n@media(max-width: 600px){\n    flex-direction:column;\n}\n"])));
export var ImageContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\nborder:1px solid  ", ";\nflex: 0 0 ", ";\nheight: ", ";\nmargin-bottom:", "px;\nposition:relative;\ndisplay:flex;\nalign-items:center;\njustify-content:center;\naspect-ratio: 16/11;\n > img{\n    width:100%;\n    height:100%;\n }\n\n"], ["\nborder:1px solid  ", ";\nflex: 0 0 ", ";\nheight: ", ";\nmargin-bottom:", "px;\nposition:relative;\ndisplay:flex;\nalign-items:center;\njustify-content:center;\naspect-ratio: 16/11;\n > img{\n    width:100%;\n    height:100%;\n }\n\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.bluePale;
}, function (props) { return props.width + "rem"; }, function (props) { return props.height + "rem"; }, function (props) { return props.marginBottom; });
export var IconContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n\nposition:absolute;\nbackground-color: ", ";\nborder:1px solid  ", ";\nheight: 24px;\nwidth: 24px;\nright: 6px;\ntop: 6px;\ndisplay:flex;\nalign-items:center;\njustify-content:center;\n\n> img{\n    padding:0;\n    width:100%;\n    height:100%;\n}\n\n"], ["\n\nposition:absolute;\nbackground-color: ", ";\nborder:1px solid  ", ";\nheight: 24px;\nwidth: 24px;\nright: 6px;\ntop: 6px;\ndisplay:flex;\nalign-items:center;\njustify-content:center;\n\n> img{\n    padding:0;\n    width:100%;\n    height:100%;\n}\n\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.bluePiv;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.bluePale;
});
export var IconContainerBig = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n\nposition:absolute;\nbackground-color: ", ";\nheight: 32px;\nwidth: 32px;\nright: 10px;\ntop: 10px;\ndisplay:flex;\nalign-items:center;\njustify-content:center;\n\n& img{\n    padding:8px;\n    width:100%;\n    height:100%;\n}\n\n"], ["\n\nposition:absolute;\nbackground-color: ", ";\nheight: 32px;\nwidth: 32px;\nright: 10px;\ntop: 10px;\ndisplay:flex;\nalign-items:center;\njustify-content:center;\n\n& img{\n    padding:8px;\n    width:100%;\n    height:100%;\n}\n\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.bluePiv;
});
export var Description = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\ndisplay:flex;\nflex-direction:column;\n\n"], ["\ndisplay:flex;\nflex-direction:column;\n\n"])));
export var CompanyTitle = styled.h4(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\nfont-size:1.18rem;\nfont-family:'Open Sans',sans-serif;\ncolor : ", ";\nfont-weight:bold;\nmargin-bottom:16px;\n"], ["\nfont-size:1.18rem;\nfont-family:'Open Sans',sans-serif;\ncolor : ", ";\nfont-weight:bold;\nmargin-bottom:16px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.blueFonce;
});
export var Category = styled.ul(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\nlist-style:none;\npadding:0;\nfont-family:'Open Sans',sans-serif;\nfont-size:1rem;\ncolor: ", "\n"], ["\nlist-style:none;\npadding:0;\nfont-family:'Open Sans',sans-serif;\nfont-size:1rem;\ncolor: ", "\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.bluePiv;
});
export var Text = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\nfont-family:'Open Sans',sans-serif;\nfont-size:14px;\ncolor : ", ";\nflex: 1 1 auto;\n& p{\n    font-family:'Open Sans',sans-serif;\n}\n\n& div{\n    display:flex;\n    gap:8px;\n    margin-bottom:16px;\n}\n\n"], ["\nfont-family:'Open Sans',sans-serif;\nfont-size:14px;\ncolor : ", ";\nflex: 1 1 auto;\n& p{\n    font-family:'Open Sans',sans-serif;\n}\n\n& div{\n    display:flex;\n    gap:8px;\n    margin-bottom:16px;\n}\n\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.blueFonce;
});
export var Date = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\nfont-family:'Open Sans',sans-serif;\nfont-size:0.875rem;\n"], ["\nfont-family:'Open Sans',sans-serif;\nfont-size:0.875rem;\n"])));
export var Tag = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\nfont-size:0.875rem;\npadding:10px 16px;\ncolor: ", ";\nbackground-color:", ";\nfont-weight:500;\nwidth:fit-content;\ncursor:pointer;\n"], ["\nfont-size:0.875rem;\npadding:10px 16px;\ncolor: ", ";\nbackground-color:", ";\nfont-weight:500;\nwidth:fit-content;\ncursor:pointer;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.bluePiv;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.bluePale;
});
// Documents
export var Action = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\ndisplay:flex;\nalign-items:center;\ncolor:", ";\nmargin:16px 0;\ngap:5px;\n"], ["\ndisplay:flex;\nalign-items:center;\ncolor:", ";\nmargin:16px 0;\ngap:5px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.bluePiv;
});
//Events
export var EventsContainer = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\ndisplay:flex;\ngap:24px;\nmargin:30px 0;\n"], ["\ndisplay:flex;\ngap:24px;\nmargin:30px 0;\n"])));
export var EventsImage = styled.div(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\ndisplay:flex;\nalign-items:center;\njustify-content:center;\n//border: 1px solid ", ";\n"], ["\ndisplay:flex;\nalign-items:center;\njustify-content:center;\n//border: 1px solid ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.grisClair;
});
export var EventsTitle = styled.h4(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\ncolor:", ";\nfont-family:'Open Sans',sans-serif;\nfont-weight:bold;\nfont-size: 1.125rem;\n"], ["\ncolor:", ";\nfont-family:'Open Sans',sans-serif;\nfont-weight:bold;\nfont-size: 1.125rem;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.blueFonce;
});
//Contact 
export var ContactContainer = styled.div(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\nborder: 1px solid ", ";\npadding:1.5rem;\nmargin:30px 0;\n"], ["\nborder: 1px solid ", ";\npadding:1.5rem;\nmargin:30px 0;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.grisClair;
});
export var ContactInfo = styled.div(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\nmargin-bottom:1rem;\n& a { text-decoration:underline;\n    }\n& img{\n    width:24px;\n    height:24px;\n    }\n"], ["\nmargin-bottom:1rem;\n& a { text-decoration:underline;\n    }\n& img{\n    width:24px;\n    height:24px;\n    }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19;
